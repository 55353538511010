import React, { useEffect } from 'react'
import Header from './header'
import TalentManagementSection from './TalentManagement'
import SocialMediaManagement from './SocialMediaManagement'
import InfluencersGrid from './InfluencersGrid'
import VideoProduction from './VideoProduction'
import FeaturedInSection from './FeaturedInSection'
import { useDispatch } from 'react-redux'
import { fetchWorkPage } from '../../redux/workPageSlice'

const Work = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWorkPage());
  }, [dispatch]);

  return (
    <>
   <Header/>
   <TalentManagementSection/>
   <SocialMediaManagement/>
   <InfluencersGrid/>
   <VideoProduction/>
   <FeaturedInSection/>

   </>
  )
}

export default Work
