import React, { useEffect, useState } from 'react'
import Header from '../../Component/Navbar/index'
import MostMediaExperienced from '../../Component/MostMediaExperienced'
import InfluencerMarketingSection from '../../Component/influencerMarkteing'
import OurMilestonesSection from '../../Component/OurMilestones'
import BrandGrid from '../../Component/Brands'
import FeaturedIn from '../../Component/Featured'
import GetInTouch from '../../Component/footer2/GetInTouch'
import ProductWebsites from './productWebsite/product_website'
import ServicesSection from './ServicesSection/services_section'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHomepage } from '../../redux/homepageSlice'
import { fetchSiteGeneral_Information } from '../../redux/contactUspageSlice'
import SlideFeaturedIn from '../../Component/Featured/slideFeatured'
import Loader from '../../Component/loader/loader'

const Home = () => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true); // Set loading state initially to true

  useEffect(()=>{
    // console.log("fetchHomepage");
    // dispatch(fetchHomepage())
    // dispatch(fetchSiteGeneral_Information("Third Party"))



    const fetchData = async () => {
      try {
        console.log("fetchHomepage");
        // Dispatch actions to fetch the homepage data and general information
        await dispatch(fetchHomepage());
        await dispatch(fetchSiteGeneral_Information("Third Party"));
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        // Set loading to false after the data is fetched or if an error occurs
        setLoading(false);
      }
    };

    fetchData();
  },[dispatch])

  const featuredMarketingData = useSelector((state)=>state.homepage.FeaturedMarketing)
  const brandsData = useSelector((state)=>state.homepage?.brands)

  console.log("featuredMarketingData",featuredMarketingData)

  if (loading) {
    // If loading is true, show the loader component
    return <Loader />;
  }



  return (
    <>
    <Header/>
    {/* <MostMediaExperienced/> */}
    <ServicesSection/>
    <InfluencerMarketingSection/>
    <OurMilestonesSection/>
    <ProductWebsites/>
    <BrandGrid brandsData={brandsData}/>
    {/* <FeaturedIn featuredMarketingData={featuredMarketingData}/> */}
    <SlideFeaturedIn featuredMarketingData={featuredMarketingData}/>
    </>
  )
}

export default Home
