import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {api} from "../contextApi/api"
import { APISURL } from "../contextApi/apiUrl";

export const fetchConnectPage = createAsyncThunk("user/fetchconnectpage",async()=>{
    try {
        const data = await api.get(APISURL.GET_CONNECTPAGE)
        return data.data
    } catch (error) {
        
    }
})

const connectpageSlice = createSlice({
    name:"connectpage",
    initialState:{
        banner:{},
        workWithUs:{},
        wantToJoin:{},
        featuredMarketing:{},
        status:"idle",
        error:null
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(fetchConnectPage.pending,(state)=>{
            state.status="loading"
        })
        .addCase(fetchConnectPage.fulfilled,(state,action)=>{
            console.log("actionaction",action);
            
            state.banner=action.payload?.banner;
            state.workWithUs=action.payload?.workWithUs;
            state.wantToJoin=action.payload?.wantToJoin;
            state.featuredMarketing=action.payload?.featuredMarketing
        })
        .addCase(fetchConnectPage.rejected,(state,action)=>{
            state.status="failed";
            state.error=action.error.message
        })
    }
})

export default connectpageSlice.reducer