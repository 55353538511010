import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import upload_document_icon from "../../../assets/upload_document.png";
import contactUs from "../../../assets/contact-us.png";
import { submitContactUsForm } from '../../../redux/contactUspageSlice';
import { showToast } from '../../../utils/toastUtils';

const ContactForm = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    fullName: '',
    emailAddress: '',
    countryCode: '+91',
    phoneNumber: '',
    subject: '',
    message: '',
    attachment: null,
  });

  const [errors, setErrors] = useState({});
  const [fileError, setFileError] = useState("");
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); // New loading state

  const formRef = useRef(null); // Reference for the form container


  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType.startsWith("video/") || fileType === "application/vnd.ms-excel" || fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {

        setFileError("Video and Excel files are not allowed.");
        setFormData({ ...formData, attachment: null }); // Reset attachment
        setUploadSuccessMessage("");
      } else {
        setFileError("");
        setFormData({ ...formData, attachment: file });
        setUploadSuccessMessage("File uploaded successfully!");
      }
    }
    e.target.value = ""; // Clear the file input value
  };

  const handleAttachmentClick = () => {
    setUploadSuccessMessage(""); // Reset the success message on file re-upload
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = 'Full Name is required';
    if (!formData.emailAddress) newErrors.emailAddress = 'Email Address is required';
    if (!formData.phoneNumber) newErrors.phoneNumber = 'Phone Number is required';
    if (!formData.message) newErrors.message = 'Message is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true); // Start loading
      const formPayload = new FormData(); // Create a FormData object to handle file upload
      formPayload.append('fullName', formData.fullName);
      formPayload.append('email', formData.emailAddress);
      formPayload.append('countryCode', formData.countryCode);
      formPayload.append('phoneNumber', formData.phoneNumber);
      formPayload.append('subject', formData.subject);
      formPayload.append('message', formData.message);
      if (formData.attachment) {
        formPayload.append('contact_us_file', formData.attachment);
      }

      try {
        const response = await dispatch(submitContactUsForm(formPayload)).unwrap(); // Handle the thunk response
        if (response.success) {
          showToast("Contact Us details have been successfully created.","success"); // Success notification
          setFormData({
            fullName: '',
            emailAddress: '',
            countryCode: '+91',
            phoneNumber: '',
            subject: '',
            message: '',
            attachment: null,
          });
        } else {
          showToast("Form submission failed. Please try again.","error");
        }
      } catch (error) {
        showToast("An unexpected error occurred.", 'error');
      } finally {
        setLoading(false); // Stop loading
      }
      handleAttachmentClick()
    } else {
      showToast("Validation failed. Please fill out all required fields.", 'error');
    }
  };




    // Click outside to hide the status message
    // useEffect(() => {
    //   const handleClickOutside = (event) => {
    //     if (formRef.current && !formRef.current.contains(event.target)) {
    //       setShowStatus(false); // Hide status message
    //     }
    //   };
  
    //   document.addEventListener('mousedown', handleClickOutside);
    //   return () => {
    //     document.removeEventListener('mousedown', handleClickOutside);
    //   };
    // }, []);



  return (
    <div ref={formRef} className="max-w-4xl mx-auto p-8 bg-white rounded-lg py-5 font-['Poppins']">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <input
              type="text"
              id="fullName"
              className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-orange-500 focus:border-orange-500 sm:text-sm placeholder-[#7B7D8F] font-[400]"
              placeholder="Full Name*"
              value={formData.fullName}
              onChange={handleInputChange}
            />
            {errors.fullName && <span className="text-red-500 text-sm">{errors.fullName}</span>}
          </div>
          <div>
            <input
              type="email"
              id="emailAddress"
              className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
              placeholder="Email Address*"
              value={formData.emailAddress}
              onChange={handleInputChange}
            />
            {errors.emailAddress && <span className="text-red-500 text-sm">{errors.emailAddress}</span>}
          </div>
          <div className="flex flex-col md:flex-row md:space-x-2">
            <div className="md:w-1/3">
              <select
                id="countryCode"
                className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                value={formData.countryCode}
                onChange={handleInputChange}
              >
                <option value="+91">+91</option>
                {/* Add more country codes here */}
              </select>
            </div>
            <div className="flex-grow mt-4 md:mt-0">
              <input
                type="text"
                id="phoneNumber"
                className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                placeholder="Phone Number*"
                value={formData.phoneNumber}
                // onChange={handleInputChange}
                onChange={(e)=>{
                    if(e.target.value.length<=10){
                      handleInputChange(e)
                    }
                }}
                maxLength={10}


                style={{
                  // For most browsers
                  appearance: 'none', 
                  MozAppearance: 'textfield', // Firefox
                  WebkitAppearance: 'none', // Chrome, Safari, Edge
                  paddingRight: '20px'  // Padding to offset the spinner area
                }}
                inputMode="numeric"
                onWheel={(e) => e.preventDefault()}  // Prevents scrolling from incrementing/decrementing value
                onKeyDown={(e) => {
                  // Allow only numbers and prevent other characters (like non-numeric input)
                  if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                    e.preventDefault();
                  }
                }}  

              />
              {errors.phoneNumber && <span className="text-red-500 text-sm">{errors.phoneNumber}</span>}
            </div>
          </div>
          <div>
            <input
              type="text"
              id="subject"
              className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
              placeholder="Subject"
              value={formData.subject}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="mt-6">
          <textarea
            id="message"
            rows="4"
            className="mt-1 block w-full px-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
            placeholder="Message..."
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
          {errors.message && <span className="text-red-500 text-sm">{errors.message}</span>}
        </div>

        <div className="mt-6">
          <input
            type="file"
            id="attachment"
            className="hidden"
            onChange={handleFileChange}
            onClick={handleAttachmentClick}
            accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
          />
          <label
            htmlFor="attachment"
            className={`${
              uploadSuccessMessage ? "text-green-500 font-medium" : "text-gray-500"
            } w-full flex justify-center items-center px-4 py-3 border border-gray-300 rounded-md shadow-sm text-sm font-[400] bg-white hover:bg-gray-50 cursor-pointer`}
          >
            <img src={upload_document_icon} alt="Upload Document" className="mr-2 h-5" />
            {uploadSuccessMessage || "Upload Attachment"}-
          </label>
          {fileError && <span className="text-red-500 text-sm">{fileError}</span>}
        </div>

        <p className="text-[14px] text-gray-500 mt-4 font-[400] leading-[24px]">
          *By submitting this form, you agree to our{' '}
          <a href="#" className="text-[#E54F26]">
            Privacy Policy
          </a>
          .
        </p>

        {/* <div className="mt-6 text-left">
          <button
            type="submit"
            className={`inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-[10px] shadow-sm text-white bg-[#E54F26] hover:bg-orange-600  focus:outline-none focus:ring focus:ring-orange-500`}
          >
            Submit Message
            <img src={contactUs} alt="Contact Us" className="ml-2 w-6 h-6" />
          </button>
        </div> */}


        <div className="mt-6 text-center">
          <button
            type="submit"
            className={`inline-flex items-center px-6 py-3 border border-transparent text-[18px] font-medium rounded-[10px] shadow-sm text-white bg-[#E54F26] hover:bg-orange-600 focus:outline-none focus:ring focus:ring-orange-500`}
            disabled={loading} // Disable button while loading
          >
            {loading ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                  ></path>
                </svg>
                Submitting...
              </>
            ) : (
              <>
                Submit Message
                <img src={contactUs} alt="Contact Us" className="ml-2 w-6 h-6" />
              </>
            )}
          </button>
        </div>

           {/* Status Messages */}
           <div className="mt-1">
          {/* {showStatus && (
            <>
              {formSubmitStatus === 'loading' && <p className="text-orange-500">Submitting...</p>}
              {formSubmitStatus === 'succeeded' && <p className="text-green-500">Form submitted successfully!</p>}
              {formSubmitStatus === 'failed' && <p className="text-red-500">Error: {formError}</p>}
            </>
          )} */}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
