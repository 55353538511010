import React, { useState } from 'react';
import logo from "../../assets/JHS_MEDIA_LOGO__WB-removebg.png";
import locationIcon from '../../assets/map-icon.png';
import callIcon from '../../assets/visit-icon.png';
import mapviewImg from '../../assets/mapview.png';
import get_in_touch from '../../assets/get_in_touch.png';
import { APISURL } from '../../contextApi/apiUrl';
import { useSelector } from 'react-redux';

const LeftSection = ({selectedLink,setSelectedLink}) => {
  
  const {footerLogo=""} = useSelector((state)=>state.homepage?.favicons_Data) || {}


  const links = ['Brands', 'Influencers', 'Career'];

  return (
    <div className=" w-full lg:w-6/12  text-black py-6 px-4 sm:py-8 sm:px-8 md:py-10 md:px-16 lg:py-20 lg:pl-48 lg:pr-0 flex flex-col  font-['Urbanist']">
      
      {/* Center the image using flexbox */}
      <div className="flex   justify-center mb-8"> {/* mb-6 for spacing below the image */}
        <img
          src={get_in_touch} // Replace `get_in_touch` with your actual image import or URL
          alt="Get in Touch"
          className="w-full max-w-[500px] h-auto  object-contain" // Adjust size as needed
        />
      </div>
      
      <div className="">
        <p className="text-base sm:text-[15px] mb-4 sm:mb-6 font-[500]">
          When there are great ideas at stake, it’s always a good idea to share. Tell us how we can make it happen for you!
        </p>
        <div className="flex flex-col sm:flex-row items-start mb-3">
          <div className="mr-4">
            <img src={locationIcon} alt="JHS Media" className="h-6 sm:h-8" />
          </div>
          <div>
            <p className="font-[300] text-[18px]">Visit us:</p>
            <p className="text-sm sm:text-base font-[400]">
            H-342, near JAWAHAR CIRCLE, Siddharth Nagar, Jagatpura,
              <span className="block"> Jaipur, Rajasthan 302017</span>
            </p>
            <div className="flex items-center mt-2">
              <img src={mapviewImg} alt="Map view" className="h-8 w-8 mr-2" />
              {/* <a href="#" className="text-sm sm:text-base font-[400]">Check on map!</a> */}
              <a href="https://www.google.com/maps?q=H-342,near+JAWAHAR+CIRCLE,+Siddharth+Nagar,+Jagatpura,+Jaipur,+Rajasthan+302017"
                target="_blank" rel="noopener noreferrer" className="text-sm sm:text-base font-[400]">
                Check on map!
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:space-x-8 mb-3 mt-8">
          <div className="flex items-start mb-3 sm:mb-0">
            <div className="mr-4">
              <img src={callIcon} className="h-6 sm:h-8" />
            </div>
            <div>
              <p className="font-[300] text-[18px]">On call inquiry:</p>
              {/* <p className="text-sm sm:text-base font-[400]">+91-8000322959</p> */}
              <a href="tel:+918000322959" className="text-sm sm:text-base font-[400]">+91-8000322959</a>
            </div>
          </div>
          <div className="flex items-start">
            <div className="mr-4">
              <img src={callIcon} alt="JHS Media" className="h-6 sm:h-8" />
            </div>
            <div>
              <p className="font-[300] text-[18px]">For Assistance:</p>
              <a href="mailto:enquiry@jhsmediagroup.com" className="text-sm sm:text-base font-[400]">enquiry@jhsmediagroup.com</a>
            </div>
            
          </div>
        </div>
      </div>
      <div className=' bottom-0 lg:top-20 mt-10'>
      <div className="flex flex-col lg:flex-row items-center lg:space-x-8  lg:-mb-11 mb-2">
        <div className="mb-2 lg:mb-0 lg:-ml-3">
          <img 
          src={`${APISURL.BASE_URL}${footerLogo}`} 
          alt="JHS Media"
           className="h-16 sm:h-[70px]" 
           onError={(e)=>{
            e.target.src=logo;
            e.target.onerror=null;
           }}
           />
        </div>
        <div className="flex items-center space-x-2 sm:space-x-7 ">
          {links.map((link, index) => (
            <div key={link} className="flex items-center">
              <div className="flex flex-col items-center">
                <button
                  onClick={() => setSelectedLink(link)}
                  className={`hover:text-orange-500 text-[20px] sm:text-base text-black ${
                    selectedLink === link ? ' text-orange-500' : ''
                  }`}
                >
                  {link}
                </button>
                {selectedLink === link && (
                  <span className="w-full h-[2px] bg-orange-500 mt-1"></span>
                )}
              </div>
              {index < links.length - 1 && (
                <span className="mx-2 text-black">|</span> // Separator between links
              )}
            </div>
          ))}
        </div>
      </div>
      </div>
    </div>
  );
};

export default LeftSection;
