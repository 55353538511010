import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../contextApi/api";
import { APISURL } from "../contextApi/apiUrl";

export const fetchWorkPage = createAsyncThunk("users/workpage",async()=>{
    try {
        const data = await api.get(APISURL.GET_WORKPAGE)
        return data.data
    } catch (error) {
        
    }
})

const workPageSlice = createSlice({
    name:"workpage",
    initialState:{
        banner: {},
        Brands:{},
        SocialMediaManagment:{},
        InfluencerMarketing:{},
        videoProduction:{},
        featuredMarketing:{},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(fetchWorkPage.pending,(state)=>{
            state.status="loading"
        })
        .addCase(fetchWorkPage.fulfilled,(state,action)=>{
            state.status="succeeded";
            state.banner = action.payload?.banner;
            state.Brands = action.payload?.Brands;
            state.SocialMediaManagment = action.payload?.SocialMediaManagment;
            state.InfluencerMarketing = action.payload?.InfluencerMarketing;
            state.videoProduction = action.payload?.videoProduction;
            state.featuredMarketing = action.payload?.featuredMarketing;
        })
        .addCase(fetchWorkPage.rejected,(state,action)=>{
            state.status="failed";
            state.error=action.error.message
        })
    }
})


export  default workPageSlice.reducer;