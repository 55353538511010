import React from 'react';
import emailIcon from '../../../assets/contact_email.png'; // Adjust the path as necessary
import phoneIcon from '../../../assets/contact_call.png'; // Adjust the path as necessary
import locationIcon from '../../../assets/contact_map.png'; // Adjust the path as necessary
import { useSelector } from 'react-redux';




const ContactChildSection = () => {

  const site_general_info = useSelector((state)=>state.contactuspage?.site_general_info)

  console.log(site_general_info,"site_general_info");
  

  const contactDetails = [
    {
      icon: emailIcon,
      title: 'Write to us',
      content: site_general_info?.email || '',
      alt: 'Email Icon',
      link: site_general_info?.email ? `mailto:${site_general_info.email}` : '',
    },
    {
      icon: phoneIcon,
      title: 'On call inquiry',
      content: site_general_info?.phone || '',
      alt: 'Phone Icon',
      link: site_general_info?.phone ? `tel:${site_general_info.phone}` : '',
    },
    {
      icon: locationIcon,
      title: 'Visit us',
      content: site_general_info?.companyAddress || '',
      alt: 'Location Icon',
      link:
        site_general_info?.latitude && site_general_info?.longitude
          ? `https://www.google.com/maps?q=${site_general_info.latitude},${site_general_info.longitude}`
          : '',
    },
  ];



  return (
    <div className="bg-white text-center py-8 font-['Poppins']">
      <p className="text-[#000000] mb-6 leading-[20px] font-[600] text-[13px] px-4 sm:px-0">
        Please write to us at enquiry@jhsmediagroup.com for any service-related queries or feedback. Alternatively, you may also use <br/>
        the Wow Digital Center to lodge a complaint or report an issue. We generally respond within 12-24 working hours.
      </p>
      <p className="text-[#E54F26] font-semibold mb-10 text-[15px] leading-[20px]">
        Timings: Monday to Saturday - 9 a.m to 6 p.m - Excluding public holidays
      </p>
      <div className="flex flex-col sm:flex-row justify-center items-center space-y-10 sm:space-y-0 sm:space-x-20">
        {contactDetails.map((detail, index) => (

          <React.Fragment key={index}>

            <div className="text-center">
                <a
                    href={detail.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block cursor-pointer"
                  >
              <img src={detail.icon} alt={detail.alt} className="mx-auto mb-4 " />
              <p className="font-[500] text-[#E54F26] text-[16px] leading-[24px]">
                {detail.title}
              </p>
              <div className="w-[30px] h-[1px] bg-[#E54F26] mx-auto  mb-2"></div>
              <div className='w-[230px]'>
              <p className="text-[#7B7D8F] font-[500] text-[14px] leading-[24px]  ">
                {detail.content}
              </p>
              </div>
              
            </a>
            </div>
            {index < contactDetails.length - 1 && (
              <div className="hidden sm:block h-[112px] w-[1px] bg-[#E0E0E0]"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ContactChildSection;
