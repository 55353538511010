// store.js
import { configureStore } from '@reduxjs/toolkit';
import homepageSlice from './homepageSlice';
import aboutpageSlice from './aboutpageSlice';
import workPageSlice from './workPageSlice';
import connectpageSlice from './connectpageSlice';
import mediapageSlice from './mediapageSlice';
import contactUspageSlice from './contactUspageSlice';

const store = configureStore({
  reducer: {
    homepage: homepageSlice,
    aboutpage:aboutpageSlice,
    workpage:workPageSlice,
    connectpage:connectpageSlice,
    mediapage:mediapageSlice,
    contactuspage:contactUspageSlice,
  },
});

export default store;
