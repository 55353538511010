import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogById } from "../../redux/aboutpageSlice";
import { APISURL } from "../../contextApi/apiUrl";
import DigitalInfluencerSection from "../aboutUs/DigitalInfluencerSection";
import Navbar from "../../Component/Navbar/header";
import Loader from "../../Component/loader/loader";

const BlogDetailPage = () => {
  const { id } = useParams(); // Get blog id from URL
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const blog = useSelector((state) => state.aboutpage?.blog_detail_data);

  // Fetch blog details by ID when component mounts
  useEffect(() => {
    const fetchBlogDetails = async () => {
      setLoading(true);
      await dispatch(fetchBlogById(id));
      setLoading(false);
    };
    fetchBlogDetails();
  }, [dispatch, id]);

  if (loading) {
    return <Loader/>; // Add loading state if needed
  }

  if (!blog) {
    return <div >Blog not found!</div>;
  }

  return (
    <>
     {/* <DigitalInfluencerSection/> */}
     <Navbar />
    <div className="max-w-[1010px] mx-auto p-6">
      <h1 className="text-4xl font-semibold text-gray-800 mb-6">{blog.title}</h1>
      <img
        src={`${APISURL.BASE_URL}${blog.image}`} // Attach BASE_URL to the image URL
        alt={blog.title}
        className="w-full h-96 object-contain mb-6 border rounded-lg"
      />
      <p className="text-xl text-gray-700 mb-6">{blog.description}</p>
      <div
        className="prose prose-lg"
        dangerouslySetInnerHTML={{ __html: blog.content }} // Assuming content is HTML-formatted
      ></div>
    </div>
    </>
  );
};

export default BlogDetailPage;
