import React from 'react';
import starIcon from '../../../assets/about-us-star.png'; // replace with the actual path to your image
import importedImage from '../../../assets/about-us-group.png'; // replace with actual path to your image
import { useSelector } from 'react-redux';
import { APISURL } from '../../../contextApi/apiUrl';

const AboutUsSection = () => {

    const abousData = useSelector((state) => state.aboutpage?.aboutus);
    console.log("abousData", abousData);

    const renderParagraphWithExactSpacing = (text) => {
        if (!text) return null;

        return text.split(/\r?\n/).map((line, index) => (
            line.trim() === '' ? (
                <br key={index} /> // Render blank lines for extra spacing
            ) : (
                <p key={index} className="text-[#606060] text-[12px] md:text-[14px] font-[400]">
                    {line}
                </p>
            )
        ));
    };

    return (
        <div className="pb-2 pt-10 font-['Urbanist'] mb-10">
            <div className="container mx-auto px-4 lg:px-16">
                {/* Section Heading */}
                <h2 className="text-center mb-12 text-[#E54F26] text-[28px] md:text-[34px] font-[800]">
                    {abousData?.mainHeading || "About Us"}
                </h2>
                {/* Cards Container */}
                <div className="flex flex-col items-center space-y-6">
                    {abousData?.data && abousData.data.length > 0 && abousData.data.map((item) => (
                        <div
                            key={item.id}
                            className="bg-white rounded-lg  flex flex-col md:flex-row w-full max-w-[1010px] "
                        >
                            {/* Left Side: Image */}
                            <div className="flex justify-center items-center w-full md:w-[20%] h-[150px] md:h-[200px] p-2 overflow-hidden">
                                <img
                                    src={`${APISURL.BASE_URL}${item.mainImageUrl}` || 'https://via.placeholder.com/300'}
                                    alt={item.title || 'No Title'}
                                    className="w-full h-full object-cover rounded-md"
                                />
                            </div>
                            {/* Right Side: Content */}
                            <div className="flex flex-col p-4 md:p-6 w-full md:w-[80%]">
                                <h3 className="text-xl md:text-2xl font-semibold text-gray-900 mb-2">
                                    {item.subheading1 || 'No Title'}
                                </h3>
                                <p className="text-gray-600 text-sm md:text-base leading-relaxed">
                                    {renderParagraphWithExactSpacing(item.paragraph1 || "")}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AboutUsSection;
