import axios from "axios";
import { APISURL } from "./apiUrl";


export const api = axios.create({
    baseURL: APISURL.BASE_URL,
});


const unauthError = () => {
    localStorage.clear();
    // window.location.href = '/auth/login'
}


export const handleGetHomePage = async () => {
    try {
        const res = await api.get(APISURL.GET_HOMEPAGE);
        return res;
    } catch (error) {
        unauthError();  console.error(error);
    }
}


//// about page

export const handleGetAboutPage = async () => {
    try {
        const res = await api.get(APISURL.GET_ABOUTPAGE);
        return res;
    } catch (error) {
        unauthError();  console.error(error);
    }
}



// Function to add Footer_Brand
export const addFooterBrand = async (formData) => {
    try {
      const res = await api.post(APISURL.ADD_BRANDS_FORM_DATA, formData);
      return res.data;
    } catch (error) {
      unauthError();
      console.error('Error adding Footer_Brand:', error);
      throw error;
    }
  };
  
  // Function to add Footer_Influencer
  export const addFooterInfluencer = async (formData) => {
    try {
      const res = await api.post(APISURL.ADD_INFLUENCERS_FORM_DATA, formData);
      return res.data;
    } catch (error) {
      unauthError();
      console.error('Error adding Footer_Influencer:', error);
      throw error;
    }
  };
  
  // Function to add Footer_Career
  export const addFooterCareer = async (formData) => {
    try {
      const res = await api.post(APISURL.ADD_CAREER_FORM_DATA, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }, // For file uploads
      });
      return res.data;
    } catch (error) {
      unauthError();
      console.error('Error adding Footer_Career:', error);
      throw error;
    }
  };

