// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APISURL } from '../contextApi/apiUrl';
import {api} from "../contextApi/api"


// API call for fetching users
export const fetchAboutpage = createAsyncThunk('users/fetchaboutpage', async () => {
    const data = await api.get(APISURL.GET_ABOUTPAGE);

    return data.data;
});

export const fetchBlogs = createAsyncThunk('users/fetchBlogs', async () => {
  const data = await api.get(APISURL.GET_BLOGS);
  return data.data;
});


// Replace with your API function to fetch a single blog by ID
export const fetchBlogById = createAsyncThunk(
  "aboutpage/fetchBlogById",
  async (id) => {
    const response = await api.get(`${APISURL.GET_BLOGS}/${id}`);
    return response.data;
  }
);


const aboutpageSlice = createSlice({
  name: 'aboutpage',
  initialState: {
    banner: {},
    aboutus:{},
    asFeaturedMarketing:{},
    featuredMarketing:{},
    // blogs
    blogs_data:[],
    blog_detail_data:[],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAboutpage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAboutpage.fulfilled, (state, action) => {
        console.log("action.payload",action.payload);
        
        state.status = 'succeeded';
        state.banner = action.payload?.banner;
        state.aboutus = {data:action.payload?.aboutus,mainHeading:action.payload?.aboutusMainHeading || ""};
        state.asFeaturedMarketing = action.payload?.asFeaturedMarketing;
        state.featuredMarketing = action.payload?.featuredMarketing;
      })
      .addCase(fetchAboutpage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // blogs
      .addCase(fetchBlogs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        console.log("action.payload",action.payload);
        
        state.status = 'succeeded';
        state.blogs_data = action.payload?.data;
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // blogs by id
      .addCase(fetchBlogById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBlogById.fulfilled, (state, action) => {
        console.log("action.payload",action.payload);
        state.status = 'succeeded';
        state.blog_detail_data = action.payload?.data;
      })
      .addCase(fetchBlogById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default aboutpageSlice.reducer;
