

export const APISURL = {


    // BASE_URL: "http://localhost:8000",
    BASE_URL: "https://backend.jhsmediagroup.com",

    // homepage 

    GET_HOMEPAGE: "/api/admin/homepage/1",

// get about paage
    GET_ABOUTPAGE:"/api/admin/aboutpage/1/getaboutpage",

    // get work page
    GET_WORKPAGE:"/api/admin/workpage/1",
    // get connect page
    GET_CONNECTPAGE:"/api/admin/connectpage/1",

    // get media page
    GET_MEDIAPAGE:"/api/admin/mediapage/1/getmediapage",
    // get contact us  
    GET_CONTACTUS_PAGE_BANNER:"/api/admin/contactuspage/1/banner",
    CONTACTUS_PAGE_POST_CONTACTUS_FORM:"/api/admin/contactuspage/1/createcontactdetail",
    GET_SITE_SETTING_BY_CATEGORY:"/api/admin/site-settings",

    /// footer form

    ADD_BRANDS_FORM_DATA :"/api/admin/footer-brands",
    ADD_INFLUENCERS_FORM_DATA :"/api/admin/footer-influencers",
    ADD_CAREER_FORM_DATA :"/api/admin/footer-careers",


    // get favicons icons 
    GET_FAVICONS_ICONS:"/api/admin/favicons",


    // blogs
    GET_BLOGS:"/api/admin/blogs"

};

