import React, { useEffect, useState } from 'react';
import CelioImg from "../../assets/celio-brand.png";
import fcukbrandImg from "../../assets/fcuk-brand.png";
import jockeybrandImg from "../../assets/jockey-brand.png";
import gillettebrandImg from "../../assets/gillette-brand.png";
import { useSelector } from 'react-redux';
import { APISURL } from '../../contextApi/apiUrl';
import Slider from "react-slick";

const brands = [
  { src: CelioImg, alt: 'Celio' },
  { src: fcukbrandImg, alt: 'Fcuk' },
  { src: jockeybrandImg, alt: 'Jockey' },
  { src: gillettebrandImg, alt: 'Gillette' },
  { src: CelioImg, alt: 'Celio' },
  { src: fcukbrandImg, alt: 'Fcuk' },
  { src: jockeybrandImg, alt: 'Jockey' },
  { src: gillettebrandImg, alt: 'Gillette' },
];

const BrandGrid = ({brandsData}) => {

  console.log("brandsData",brandsData)
  const [Showbrands,setShowBrands] = useState([])
  useEffect(()=>{
    if(brandsData && brandsData.length > 0){
      // console.log("brandsData",brandsData)
      setShowBrands(brandsData)
    }
    else{
      setShowBrands(brands)
    }
  },[brandsData])



  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true, // Ensures the slide is centered
    // centerPadding: "10%", // Adds padding on both sides
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          // centerPadding: "8%",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          // centerPadding: "10%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          // centerPadding: "15%", // Center-align the single slide on mobile
        },
      },
    ],
  };

  return (
    // <div className="bg-[#FFF4F1] p-4 flex justify-center items-center h-[290px]">
    //   <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
    //     {/* {brands.map((brand, index) => ( */}
    //     {Showbrands.map((brand, index) => (
    //       <div
    //         key={index}
    //         className="flex justify-center items-center w-[140px] sm:w-[180px] md:w-[220px] h-[50px] sm:h-[60px] md:h-[70px] bg-white rounded-lg shadow-lg mx-1"
    //       >
    //         <img 
    //         // src={brand.src}
    //         src={ brand?.src || `${APISURL.BASE_URL}${brand.imageUrl}`}
    //          alt={brand.alt}
    //         // className="max-w-[50%] sm:max-w-[60%] md:max-w-[70%] max-h-[50%] sm:max-h-[60%] md:max-h-[70%] object-center" 
    //         className="w-full h-full object-cover" 
    //         onError={(e)=>{
    //           e.target.src = CelioImg;
    //           e.target.onerror = null
    //         }}
    //         />
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <div className="bg-[#FFF4F1] px-4 py-3 flex justify-center items-center h-[290px]">
    <div className="w-full max-w-[1100px]">
      <Slider {...settings}>
      {Showbrands.map((brand, index) => (
            <div key={index} className="p-1">
              <div className="flex justify-center items-center w-[140px] sm:w-[180px] md:w-[220px] h-[50px] sm:h-[60px] md:h-[70px] bg-white rounded-lg shadow-lg">
                <img
                  src={brand?.src || `${APISURL.BASE_URL}${brand.imageUrl}`}
                  alt={brand.alt}
                  className="w-full h-full object-contain"
                  onError={(e) => {
                    e.target.src = CelioImg;
                    e.target.onerror = null;
                  }}
                />
              </div>
            </div>
          ))}
      </Slider>
    </div>
  </div>
  );
};

export default BrandGrid;
