import React, { useState, useEffect, useRef } from 'react';
import Navbar from './header';
import LeftContainer from './leftContainer';
import backImg from "../../assets/header_new_bg.png";
import SocialMediaCard from './rightContainer';
import emailIcon from "../../assets/email-logo.png";
import whatsappIcon from "../../assets/whatsapp-icon.png";
import phoneIcon from "../../assets/phone-icon.png";


// right side icons
import facebookIcon from "../../assets/social_icon/Facebook.svg";
import InstaIcon from "../../assets/social_icon/Insta.svg";
import linkedinIcon from "../../assets/social_icon/Linkedin.svg";
import picsartIcon from "../../assets/social_icon/Printrest.svg";
import twitterIcon from "../../assets/social_icon/twitter.svg";
import { useSelector } from 'react-redux';


const ParentContainer = () => {

  const bannerData = useSelector((state) => state.homepage.banner);

  console.log("bannerData",bannerData);
  


  // Generate slides dynamically from bannerData
  const slides = bannerData?.map((data) => ({
    id: data.id,
    content: (
      <div className="flex flex-row items-center">
        <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
          {/* Pass dynamic data to LeftContainer */}
          <LeftContainer
            title1={data.title}
            title2={data.title2}
            description={data.description}
            buttonPair={data.buttonPair}
          />
        </div>
        <div className="w-full lg:w-1/2">
          {/* Pass dynamic image to RightContainer */}
          <SocialMediaCard 
          bannerImage={data.bannerImage}
          
          />
        </div>
      </div>
    ),
  }));


  const site_general_info = useSelector((state)=>state.contactuspage?.site_general_info)

  console.log("site_general_info",site_general_info);
  

  // Clone the first slide to create an infinite loop effect
  const extendedSlides = [...slides, slides[0]];

  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const transitionRef = useRef(null);

  // Function to handle the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  // Auto-slide functionality
  useEffect(() => {
    const interval = setInterval(nextSlide, 10000); // Slide every 3 seconds
    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);

  // Effect to handle infinite scrolling
  useEffect(() => {
    if (currentIndex === extendedSlides.length - 1) {
      // If we reach the cloned slide, reset to the first slide without animation
      transitionRef.current = setTimeout(() => {
        setCurrentIndex(0);
        sliderRef.current.style.transition = "none"; // Disable transition
        setTimeout(() => {
          sliderRef.current.style.transition = "transform 0.7s ease-in-out"; // Re-enable transition
        }, 50);
      }, 700); // Wait for the slide transition before resetting
    }

    return () => {
      clearTimeout(transitionRef.current); // Cleanup timeout
    };
  }, [currentIndex, extendedSlides.length]);

  return (
    // <div className="relative bg-cover bg-center min-h-screen lg:h-[570px]" style={{ backgroundImage: `url(${backImg})` }}>
    // <div className="relative bg-cover bg-center mb-10 lg:h-[570px]" style={{ backgroundImage: `url(${backImg})` }}>
    <div className="relative bg-cover bg-center mb-10 " style={{ backgroundImage: `url(${backImg})` }}>
      <Navbar />

      {/* Fixed Positioned Left Social Icons with higher z-index */}
      <div
        style={{
          background: 'linear-gradient(94.37deg, #171719 -56.81%, #E54F26 -3.21%, #E54F26 44.09%, #FF8665 100.85%)',
          zIndex: 1000, // Higher z-index to make sure it stays on top
        }}
        className="fixed right-2 top-1/2 transform -translate-y-1/2  md:right-0   w-[48px] h-[130px] md:w-[56px] md:h-[150px] flex items-center md:items-start justify-between flex-col space-y-2 md:space-y-4 lg:p-3 p-2"
      >
        <button className="p-1 rounded-full">
          <a 
          href={site_general_info?.whatsapp || "#"}
          target='_blank'
          rel='noopener noreferrer'
          >
          <img src={whatsappIcon} className="w-5 h-5 md:w-6 md:h-6" alt="WhatsApp" />
          </a>
        </button>

        <button className="p-1 rounded-full">
        <a
          href={`mailto:${site_general_info?.email}` || "#"}
          target="_blank"
          rel="noopener noreferrer"
        >

          <img src={emailIcon} className="w-5 h-4 md:w-6 md:h-5" alt="Email" />
          </a>
        </button>
        <button className="p-1 rounded-full">
        <a
          href={`tel:${site_general_info?.callToAction}` || "#"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={phoneIcon} className="w-5 h-5 md:w-6 md:h-6" alt="Phone" />
          </a>
        </button>
      </div>

    
        {/* Fixed Positioned Right Social Icons */}
{/* Fixed Positioned Right Social Icons */}
<div className="fixed left-2  z-[999] bottom-4 md:bottom-10">
  <div className="flex flex-col items-center space-y-4">
    {/* Facebook */}
    <div className="p-1">
      <a
        href={site_general_info?.facebook || "#"}
         target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={facebookIcon}
          className="w-[25px] h-[28px] transition-transform transform hover:scale-110 hover:shadow-md cursor-pointer"
          alt="Facebook"
        />
      </a>
    </div>

    {/* Instagram */}
    <div className="p-1">
      <a
          href={site_general_info?.instagram || "#"}
          target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={InstaIcon}
          className="w-[26px] h-[29px] transition-transform transform hover:scale-110 hover:shadow-md cursor-pointer"
          alt="Instagram"
        />
      </a>
    </div>

    {/* Twitter */}
    <div className="p-1">
      <a
        href={site_general_info?.twitter || "#"}
         target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={twitterIcon}
          className="w-[26px] h-[29px] transition-transform transform hover:scale-110 hover:shadow-md cursor-pointer"
          alt="Twitter"
        />
      </a>
    </div>

    {/* LinkedIn */}
    <div className="p-1">
      <a
        href={site_general_info?.linkedin || "#"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={linkedinIcon}
          className="w-[26px] h-[29px] transition-transform transform hover:scale-110 hover:shadow-md cursor-pointer"
          alt="LinkedIn"
        />
      </a>
    </div>

    {/* Picsart */}
    <div className="p-1">
      <a
         href={site_general_info?.pinterest || "#"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={picsartIcon}
          className="w-[26px] h-[29px] transition-transform transform hover:scale-110 hover:shadow-md cursor-pointer"
          alt="Picsart"
        />
      </a>
    </div>
  </div>
</div>


      {/* <div className="px-4 lg:px-3 py-10 overflow-hidden max-w-[1200px] mx-auto"> */}
      <div className="px-4 lg:px-3 py-10 overflow-hidden max-w-[1200px] mx-auto">
      {/* <div className="px-4 lg:px-3  overflow-hidden"> */}
        <div
          ref={sliderRef}
          className="flex transition-transform duration-700 ease-in-out"
          style={{
            width: `${extendedSlides?.length * 100}%`, // Set width relative to number of slides
            transform: `translateX(-${currentIndex * (100 / extendedSlides?.length)}%)` // Move slides
          }}
        >
          {extendedSlides?.map((slide, index) => (
            <div
              key={index}
              className="w-full flex-shrink-0"
              style={{ width: `${100 / extendedSlides.length}%` }} // Set slide width
            >
              {slide?.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ParentContainer;
