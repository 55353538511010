import React, { useEffect } from 'react'
import DigitalInfluencerSection from './DigitalInfluencerSection'
import AboutUsSection from './AboutUsSection'
import FeaturedInSection from './FeaturedInSection'
import FeaturedIn from '../../Component/Featured'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAboutpage } from '../../redux/aboutpageSlice'
import ImageWithContent from './AboutUsSection/secChangeAboutUs'
import BlogsPage from './blogs'

const AboutUs = () => {

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(fetchAboutpage())
  },[])

  const featuredMarketingData = useSelector((state)=>state.aboutpage.featuredMarketing)
  console.log("asfeaturedMarketingData",featuredMarketingData)


  return (
    <>
      <DigitalInfluencerSection/>
      {/* <AboutUsSection/> */}
      {/* <AboutUsSection/> */}
      <ImageWithContent/>
      {/* <FeaturedInSection/> */}
      {/* <FeaturedIn  featuredMarketingData={featuredMarketingData} /> */}
      {/* <BlogsPage/> */}
    </>
  )
}

export default AboutUs
