import React, { useEffect } from 'react'
import HeaderSection from './header'
import MarketingSection from './Marketing'
import { useDispatch } from 'react-redux'
import { fetchMediaPage } from '../../redux/mediapageSlice'

const MediaSection = () => {
    const dispatch = useDispatch()
    useEffect(()=>{
      dispatch(fetchMediaPage())
    },[dispatch])
  return (
    <>
      <HeaderSection/>
      <MarketingSection/>
    </>
  )
}

export default MediaSection
