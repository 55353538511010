import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ProfileCard from './ProfileCard';
import { APISURL } from '../../contextApi/apiUrl';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SlideFeaturedIn = ({ bg, featuredMarketingData }) => {
  const [showfeaturedMarketingData, setShowfeaturedMarketingData] = useState({
    heading: "",
    ProjectsCard: [],
  });

  useEffect(() => {
    if (featuredMarketingData) {
      const data = {
        heading: featuredMarketingData?.heading,
        ProjectsCard: featuredMarketingData?.homepagefeaturedProjectsCard || [],
      };

      // If only one card is present, duplicate it
      if (data.ProjectsCard.length === 1) {
        data.ProjectsCard = [...data.ProjectsCard, ...data.ProjectsCard]; // Duplicate the single card
      }

      setShowfeaturedMarketingData(data);
    }
  }, [featuredMarketingData]);

  // Custom navigation buttons
  const CustomPrevArrow = ({ onClick }) => (
    <button
      className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-full p-2 shadow-lg"
      onClick={onClick}
    >
      <FaChevronLeft size={20} />
    </button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <button
      className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-full p-2 shadow-lg"
      onClick={onClick}
    >
      <FaChevronRight size={20} />
    </button>
  );

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: showfeaturedMarketingData.ProjectsCard.length > 1,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={`bg-${bg || 'white'} py-12`}>
      <div className="max-w-7xl mx-auto px-4 md:px-8 lg:px-[100px] relative">
        <p className="text-xl md:text-2xl font-semibold mb-5 px-2 text-center md:text-left">
          {showfeaturedMarketingData.heading || 'As Featured In The '}
          <span className="italic text-gray-700">JHS Media </span>
        </p>
        <Slider {...sliderSettings}>
          {showfeaturedMarketingData?.ProjectsCard?.length > 0
            ? showfeaturedMarketingData?.ProjectsCard.map((item, index) => (
                <div
                  key={index}
                  className="p-4" // Add consistent padding around cards
                >
                  <ProfileCard
                    image={`${APISURL.BASE_URL}${item?.imageUrl}`}
                    title={item?.title}
                    description={item?.description}
                    name={item?.name}
                    position={item?.position}
                  />
                </div>
              ))
            : Array(3)
                .fill(null)
                .map((_, index) => (
                  <div
                    key={index}
                    className="p-4" // Add consistent padding around cards
                  >
                    <ProfileCard />
                  </div>
                ))}
        </Slider>
      </div>
    </div>
  );
};

export default SlideFeaturedIn;
