import React, { useState } from 'react';
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import footer_image from "../../assets/footer_image.png"
const GetInTouch = () => {
  const [selectedLink, setSelectedLink] = useState('Brands'); // Default to "Brands"

  return (
    <>
    <div className="relative flex flex-col lg:flex-row ">
      <LeftSection selectedLink={selectedLink} setSelectedLink={setSelectedLink} />
      <RightSection selectedLink={selectedLink} />

    </div>
    <div className='w-full'>
        <img 
        src={footer_image} 
        alt="Footer" 
        className='h-auto w-full object-cover'
        />
      </div>
    </>
  );
};

export default GetInTouch;
