import React from 'react';
import { useSelector } from 'react-redux';
import { APISURL } from '../../../contextApi/apiUrl';
import image1 from '../../../assets/video_production1.png'; // Fallback image
import watchvideoicon from '../../../assets/watch-video.png'; // New watch video icon

const VideoProduction = () => {
  // Get video production data from Redux state
  const videoProductionData = useSelector((state) => state.workpage?.videoProduction);

  return (
    <div className="flex flex-col px-4 md:px-[192px] font-['Urbanist'] lg:-mt-5 py-12">
      <h2 className="text-3xl font-semibold mb-4 mt-8 lg:px-1 text-[#1B2430]">
        {videoProductionData?.heading || 'Video Production'}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-4">
        {videoProductionData?.videos?.map((video) => (
          <div
            key={video.id}
            className="relative bg-white rounded-lg overflow-hidden shadow-lg w-full h-[300px]"
          >
            <a href={video.videolink} target="_blank" rel="noopener noreferrer" className="relative block w-full h-full">
              {/* Image with fallback */}
              <img
                src={video.videoThumbnail ? `${APISURL.BASE_URL}${video.videoThumbnail}` : image1}
                alt={`Video ${video.id}`}
                className="w-full h-full object-fill"
                onError={(e)=>{
                    e.target.src=image1;
                    
                }}
              />
              {/* Watch Video Icon with Pulsing Animation */}
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="absolute inset-0 flex items-center justify-center">
                  <span className="absolute inline-block h-8 w-8 md:h-10 md:w-10 rounded-full bg-[#E54F26] opacity-20 animate-ping"></span>
                  <span className="absolute inline-block h-8 w-8 md:h-10 md:w-10 rounded-full bg-[#E54F26] opacity-30 animate-ping delay-100"></span>
                  <span className="absolute inline-block h-8 w-8 md:h-10 md:w-10 rounded-full bg-[#E54F26] opacity-50 animate-ping delay-200"></span>
                </span>
                <img
                  src={watchvideoicon}
                  className="h-10 md:h-14 w-10 md:w-14 relative z-10 rounded-[60px]"
                  alt="Watch Video"
                />
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoProduction;
