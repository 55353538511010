import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast"; 
import GetInTouch from "./Component/footer2/GetInTouch";

import Home from "./screens/Home";
import AboutUs from "./screens/aboutUs";
import Work from "./screens/work";
import Connect from "./screens/connect";
import MediaSection from "./screens/media";
import ContactSection from "./screens/contact";
import ContactPage from "./Component/footer3/ContactPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchFaviconsIcons } from "./redux/homepageSlice";
import { APISURL } from "./contextApi/apiUrl";
import BlogDetailPage from "./screens/blogsDetails/blogsdetails";
import NotFoundPage from "./Component/404NotFound/notFound";


function ScrollToTop() {
  const location = useLocation();  // Track the current location (route)

  useEffect(() => {
    // Scroll to top smoothly whenever route changes
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',  // Enable smooth scroll
    });
  }, [location]);  // Run this effect whenever location (route) changes

  return null; // This component doesn't render anything
}




function MainApp() {

  const dispatch = useDispatch()


    useEffect(()=>{
      dispatch(fetchFaviconsIcons())
    },[dispatch])

    const {favicon=""} = useSelector((state)=>state.homepage?.favicons_Data) || {}


 // Set the favicon dynamically
 useEffect(() => {
  if (favicon) {
    const fullFaviconUrl = `${APISURL.BASE_URL}${favicon}`; // Full URL for favicon
    let faviconElement = document.querySelector('link[rel="icon"]');

    if (faviconElement) {
      // If <link rel="icon"> exists, update the href
      faviconElement.href = fullFaviconUrl;
    } else {
      // If <link rel="icon"> does not exist, create one
      faviconElement = document.createElement("link");
      faviconElement.rel = "icon";
      faviconElement.href = fullFaviconUrl;
      document.head.appendChild(faviconElement);
    }
  }
}, [favicon]);


 // Check current location
 const location = useLocation();
   // List of valid routes
   const validRoutes = [
    "/",
    "/about",
    "/work",
    "/connect",
    "/media",
    "/contact",
    "/blogs/:id/:slug",
  ];

    // Check if the current route is valid
    const isValidRoute = validRoutes.some((route) => {
      if (route.includes(":")) {
        // Handle dynamic routes like /blogs/:id/:slug
        const baseRoute = route.split("/:")[0];
        return location.pathname.startsWith(baseRoute);
      }
      return location.pathname === route;
    });


  return (
    <>
      <ScrollToTop/>
      <Toaster position="top-right" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/work" element={<Work />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/media" element={<MediaSection />} />
        <Route path="/contact" element={<ContactSection />} />
        <Route path="/blogs/:id/:slug" element={<BlogDetailPage />} />
        <Route path="*" element={<NotFoundPage/>} />
      </Routes>
      {/* <GetInTouch /> */}
    {isValidRoute && <ContactPage/>}
    </>
  );
}


function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}



export default App;

// import React from 'react';

// const GetInTouch = () => {
//   return (
//     <h2 
//       className="text-[36px] sm:text-[48px] font-[900] text-left mb-6 tracking-wide text-[#272727] leading-tight sm:leading-[60px]" 
//       style={{ 
//         WebkitTextStroke: '2px #FFFFFF80', 
//         border: '2px solid #FFFFFF80' 
//       }}
//     >
//       GET IN TOUCH
//     </h2>
//   );
// };

// export default GetInTouch;


