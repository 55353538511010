import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../contextApi/api";
import { APISURL } from "../contextApi/apiUrl";

export const fetchMediaPage = createAsyncThunk("users/mediapage",async()=>{
    try {
        const data = await api.get(APISURL.GET_MEDIAPAGE)
        return data.data
    } catch (error) {
        
    }
})

const mediaPageSlice = createSlice({
    name:"mediapage",
    initialState:{
        banner: {},
        asFeaturedMarketing:{},
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(fetchMediaPage.pending,(state)=>{
            state.status="loading"
        })
        .addCase(fetchMediaPage.fulfilled,(state,action)=>{
            state.status="succeeded";
            state.banner = action.payload?.banner;
            state.asFeaturedMarketing = action.payload?.asFeaturedMarketing;
        })
        .addCase(fetchMediaPage.rejected,(state,action)=>{
            state.status="failed";
            state.error=action.error.message
        })
    }
})


export  default mediaPageSlice.reducer;