import { toast } from 'react-hot-toast';

/**
 * Displays a toast message based on the type.
 *
 * @param {string} message - The message to display.
 * @param {'success' | 'error'} type - The type of the toast (success or error).
 */
export const showToast = (message, type = 'success') => {
  if (type === 'success') {
    toast.success(message);
  } else if (type === 'error') {
    toast.error(message);
  }
};
