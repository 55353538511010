import React, { useEffect, useState } from 'react';
import MilestonesSection from './MilestonesSection';
import AwardsGrid from './AwardsGrid';
import FeaturedProjectsSection from './FeaturedProjects';
import { useSelector } from 'react-redux';

const OurMilestonesSection = () => {
    const OurMilestoneData = useSelector((state)=>state.homepage.OurMilestones)
    const FeaturedProjects = useSelector((state)=>state.homepage.FeaturedProjects)
    console.log("FeaturedProjects",FeaturedProjects)

    const [OurMilestoneTopData,setOurMilestoneTopData] = useState({heading:"",subheading:"",description:""})
    const [OurMilestoneCardsData,setOurMilestoneCardsData] = useState([])
    const [FeaturedProjectsData , setFeaturedProjectsData] = useState([])
  useEffect(()=>{
    if(OurMilestoneData){
      setOurMilestoneTopData((prev)=>({...prev,heading:OurMilestoneData?.heading,subheading:OurMilestoneData?.subHeading,description:OurMilestoneData?.description}))
    
      if(OurMilestoneData?.cards){
        setOurMilestoneCardsData(OurMilestoneData?.cards)
      }
    }
    if(FeaturedProjects){
      setFeaturedProjectsData(FeaturedProjects)
    }
  },[OurMilestoneData,FeaturedProjects])
     
    

  return (
    <div>
        <MilestonesSection 
          heading={OurMilestoneTopData?.heading}
          subheading={OurMilestoneTopData?.subheading}
          description={OurMilestoneTopData?.description}
        />
        <AwardsGrid 
          OurMilestoneCardsData={OurMilestoneCardsData}
        />
        <FeaturedProjectsSection
        
          FeaturedProjectsData={FeaturedProjectsData}
        />
    </div>
  );
};

export default OurMilestonesSection;
