import React, { useEffect, useState } from 'react';
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import { useSelector } from 'react-redux';
import { APISURL } from '../../../contextApi/apiUrl';

const CareerSection = () => {
  const [selectedJob, setSelectedJob] = useState('Front End Developer');
  const [key, setKey] = useState(0); // Key to trigger re-render

  const handleJobClick = (jobTitle) => {
    setSelectedJob(jobTitle?.id);
    setKey(prevKey => prevKey + 1); // Increment key to re-render RightSection
    filterJobDetail(jobTitle?.id)
  };

  const filterJobDetail = (id)=>{
       if(id && leftSectionData?.jobList?.length>0){
      const selectedJobData = wantToJoinData?.DetailsCard?.find(item=>item?.id==id)
      if(selectedJobData){
          const data = {
            image:`${APISURL.BASE_URL}${selectedJobData?.cardimageUrl}`,
            subheading:selectedJobData?.cardSubHeading,
            description:selectedJobData?.cardDescription,
            PreRequisites:Array.isArray(selectedJobData?.cardjobDetails) ? selectedJobData.cardjobDetails.map((item,idx)=>({
              heading:item?.heading,
            })) : []
          }
        setRightSection(data)
      }
    }
  }


  const wantToJoinData = useSelector((state)=>state.connectpage?.wantToJoin)
  console.log("wantToJoinData",wantToJoinData);
  
  const [leftSectionData,setLeftSection] = useState({heading:"",description:"",jobList:[]})
  const [rightSectionData,setRightSection] = useState({subheading:"",image:"",description:"",PreRequisites:[]})

  useEffect(()=>{
      if(wantToJoinData && wantToJoinData?.DetailsCard?.length>0){
        const data = {
          heading:wantToJoinData?.heading,
          description:wantToJoinData?.description,
          jobList:Array.isArray(wantToJoinData?.DetailsCard) ? wantToJoinData?.DetailsCard.map((item,idx)=>({heading:item?.heading,id:item?.id})) : []
        }
        setLeftSection(data)

 const selectedJobData = wantToJoinData?.DetailsCard?.find((item,idx)=>idx==0)
          if(selectedJobData){
              const data = {
                subheading:selectedJobData?.cardSubHeading,
                image:`${APISURL.BASE_URL}${selectedJobData?.cardimageUrl}`,
                description:selectedJobData?.cardDescription,
                PreRequisites:Array.isArray(selectedJobData?.cardjobDetails) ? selectedJobData.cardjobDetails.map((item,idx)=>({
                  heading:item?.heading,
                })) : []
              }
            setRightSection(data)
          }        
      }
  },[wantToJoinData])   



  return (
    <div className="flex flex-col md:flex-row h-auto md:h-[700px]">
      <LeftSection 
      onJobClick={handleJobClick} 
      className="w-full md:w-1/2"  
      heading={leftSectionData?.heading}
      description={leftSectionData?.description}
      jobList={leftSectionData?.jobList}
      />
      <RightSection
       key={key}
       subheading={rightSectionData?.subheading} 
       selectedJob={selectedJob} 
       image={rightSectionData?.image}
       description={rightSectionData?.description}
       PreRequisites={rightSectionData?.PreRequisites}
       className="w-full md:w-1/2" 
       />
    </div>
  );
};

export default CareerSection;
