import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { submitContactUsForm } from '../../redux/contactUspageSlice';
import arrowIcon from '../../assets/contact-us.png';
import { addFooterBrand, addFooterCareer, addFooterInfluencer } from '../../contextApi/api';

const RightSection = ({ selectedLink }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    website: '',
    message: '',
    city: '',
    socialPlatform: '',
    link: '',
    followers: '',
    location: '',
    position: '',
    notRobot: false,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : files ? files[0] : value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    // Common Fields
    if (!formData.fullName) newErrors.fullName = 'Full Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phone) newErrors.phone = 'Phone Number is required';
    if (!formData.message) newErrors.message = 'Message is required';
    if (!formData.notRobot) newErrors.notRobot = 'Please confirm you are not a robot';

    // Fields for Brands
    if (selectedLink === 'Brands' && !formData.website) {
      newErrors.website = 'Website is required';
    }

    // Fields for Influencers
    if (selectedLink === 'Influencers') {
      if (!formData.city) newErrors.city = 'City is required';
      if (!formData.socialPlatform) newErrors.socialPlatform = 'Social Platform is required';
      if (!formData.link) newErrors.link = 'Profile Link is required';
      if (!formData.followers) newErrors.followers = 'Number of Followers is required';
    }

    // Fields for Career
    if (selectedLink === 'Career') {
      if (!formData.location) newErrors.location = 'Location is required';
      if (!formData.position) newErrors.position = 'Position Applied For is required';
      if (!formData.file) newErrors.file = 'Please upload a file';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      
      try {
        let response;
        switch (selectedLink) {
            case 'Brands':
              response = await addFooterBrand({
                fullName: formData.fullName,
                email: formData.email,
                phone: formData.phone,
                website: formData.website,
                message: formData.message,
              });
              break;
    
            case 'Influencers':
              response = await addFooterInfluencer({
                fullName: formData.fullName,
                email: formData.email,
                phone: formData.phone,
                city: formData.city,
                socialPlatform: formData.socialPlatform,
                link: formData.link,
                followers: parseInt(formData.followers, 10),
                message: formData.message,
              });
              break;
    
            case 'Career':
              const careerFormData = new FormData();
              careerFormData.append('fullName', formData.fullName);
              careerFormData.append('email', formData.email);
              careerFormData.append('phone', formData.phone);
              careerFormData.append('location', formData.location);
              careerFormData.append('position', formData.position);
              careerFormData.append('message', formData.message);
              careerFormData.append('file', formData.file);
    
              response = await addFooterCareer(careerFormData);
              break;
    
            default:
              throw new Error('Invalid form type');
          }
    
          console.log('Form submitted successfully:', response);
          alert('Form submitted successfully!');
          setFormData({
            fullName: '',
            email: '',
            phone: '',
            website: '',
            city: '',
            socialPlatform: '',
            link: '',
            followers: '',
            location: '',
            position: '',
            message: '',
            file: null,
          });
        setErrors({});
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('Failed to submit form. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="w-full lg:w-7/12  text-black py-6 px-4 sm:py-8 sm:px-8 md:py-10 md:px-20 lg:py-8 lg:pr-40 flex flex-col justify-between font-['Urbanist']">
      <form className="p-4 sm:p-6 rounded-lg mb-6" onSubmit={handleSubmit}>
        {/* Common Fields */}
        <div className="mb-3">
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            placeholder="Name"
            className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3]"
          />
          {errors.fullName && <p className="text-red-500 text-sm">{errors.fullName}</p>}
        </div>
        <div className="mb-3">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email Address"
            className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3]"
          />
          {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
        </div>
        <div className="mb-3">
          <input
            type="text"
            // type="text"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="Phone Number"
            className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3]"
            style={{
              // For most browsers
              appearance: 'none', 
              MozAppearance: 'textfield', // Firefox
              WebkitAppearance: 'none', // Chrome, Safari, Edge
              paddingRight: '20px'  // Padding to offset the spinner area
            }}
            inputMode="numeric"
            onWheel={(e) => e.preventDefault()}  // Prevents scrolling from incrementing/decrementing value
            onKeyDown={(e) => {
              // Allow only numbers and prevent other characters (like non-numeric input)
              if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                e.preventDefault();
              }
            }}
          
          
          />
          {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
        </div>

        {/* Conditional Fields */}
        {selectedLink === 'Brands' && (
          <>
            <div className="mb-3">
              <input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                placeholder="Website"
                className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3]"
              />
                {errors.website && <p className="text-red-500 text-sm">{errors.website}</p>}
            </div>
          </>
        )}

        {selectedLink === 'Influencers' && (
          <>
            <div className="mb-3">
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="City"
                className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3]"
              />
               {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="socialPlatform"
                value={formData.socialPlatform}
                onChange={handleInputChange}
                placeholder="Social Platform(example Youtube , Instagram)"
                className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3]"
              />
               {errors.socialPlatform && <p className="text-red-500 text-sm">{errors.socialPlatform}</p>}
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="link"
                value={formData.link}
                onChange={handleInputChange}
                placeholder="Profile Link"
                className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3]"
              />
               {errors.link && <p className="text-red-500 text-sm">{errors.link}</p>}
            </div>
            <div className="mb-3">
              <input
                // type="number"
                type="text"
                name="followers"
                value={formData.followers}
                onChange={handleInputChange}
                placeholder="Number of Followers / Subscribes on the Social platform"
                className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3]"
              
                style={{
                  // For most browsers
                  appearance: 'none', 
                  MozAppearance: 'textfield', // Firefox
                  WebkitAppearance: 'none', // Chrome, Safari, Edge
                  paddingRight: '20px'  // Padding to offset the spinner area
                }}
                inputMode="numeric"
                onWheel={(e) => e.preventDefault()}  // Prevents scrolling from incrementing/decrementing value
                onKeyDown={(e) => {
                  // Allow only numbers and prevent other characters (like non-numeric input)
                  if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                    e.preventDefault();
                  }
                }}
              />
              {errors.followers && <p className="text-red-500 text-sm">{errors.followers}</p>}
            </div>
          </>
        )}

        {selectedLink === 'Career' && (
          <>
            <div className="mb-3">
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                placeholder="Location (city)"
                className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3]"
              />
              {errors.location && <p className="text-red-500 text-sm">{errors.location}</p>}
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="position"
                value={formData.position}
                onChange={handleInputChange}
                placeholder="Position Applied For"
                className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3]"
              />
              {errors.position && <p className="text-red-500 text-sm">{errors.position}</p>}
            </div>
            <div className="mb-3">
              <input
                type="file"
                name="file"
                onChange={handleInputChange}
                className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3]"
              />
              {errors.file && <p className="text-red-500 text-sm">{errors.file}</p>}
            </div>
          </>
        )}

        {/* Common Message Field */}
        <div className="mb-3">
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder="Message"
            className="w-full p-2 sm:p-3 rounded-lg border border-gray-700 bg-white text-black placeholder-[#B3B3B3] h-24 sm:h-32"
          ></textarea>
          {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
        </div>

        {/* Not a Robot */}
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center">
            <input
              type="checkbox"
              name="notRobot"
              checked={formData.notRobot}
              onChange={handleInputChange}
              id="not-robot"
              className="mr-2"
            />
            <label htmlFor="not-robot" className="text-sm sm:text-base">I’m not a robot</label>
            {errors.notRobot && <p className="text-red-500 text-sm ml-4">{errors.notRobot}</p>}
          </div>
          <button
            type="submit"
            className={`bg-[#E54F26] hover:bg-orange-600 text-[#FFFFFF] font-semibold py-2 sm:py-3 px-4 sm:px-6 rounded-lg flex items-center space-x-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
          >
            {loading ? (
              <span className='tracking-[2px]'>Submitting...</span>
            ) : (
              <>
                <span className='tracking-[2px]'>Submit</span>
                <img src={arrowIcon} alt="Arrow" className="w-5 h-5" />
              </>
            )}
          </button>
        </div>
      </form>
      <div className="text-[20px] sm:text-base text-center mt-auto mb-2 text-black font-[400]">
        © JHS Media Marketing 2024. All rights reserved.
      </div>
    </div>
  );
};

export default RightSection;
