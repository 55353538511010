import React from 'react';
import FeaturedIn from '../../../Component/Featured';
import { useSelector } from 'react-redux';
import SlideFeaturedIn from '../../../Component/Featured/slideFeatured';

const FeaturedInSection = () => {
 
  const featuredMarketingData = useSelector((state)=>state.workpage?.featuredMarketing)
  console.log("featuredMarketingData",featuredMarketingData);
  
  return (
    <section className="bg-[#FFF4F0] ">
        {/* <FeaturedIn bg={"#FFF4F0"} featuredMarketingData={featuredMarketingData}/> */}
        <SlideFeaturedIn bg={"#FFF4F0"} featuredMarketingData={featuredMarketingData}/>
    </section>
  );
};

export default FeaturedInSection;
