import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../contextApi/api";
import { APISURL } from "../contextApi/apiUrl";

export const fetchContactUsPageBanner = createAsyncThunk("users/contactpagebanner",async()=>{
    try {
        const data = await api.get(APISURL.GET_CONTACTUS_PAGE_BANNER)
        return data.data
    } catch (error) {
        
    }
})




export const fetchSiteGeneral_Information = createAsyncThunk("users/fetchSiteGeneral_Information",async(category="General Information")=>{
  try {
      const data = await api.get(`${APISURL.GET_SITE_SETTING_BY_CATEGORY}/${category}`)
      return data.data 
  } catch (error) {
      
  }
})



export const submitContactUsForm = createAsyncThunk(
    "users/submitcontactusform",
    async (formData, { rejectWithValue }) => {
      try {
        const response = await api.post(APISURL.CONTACTUS_PAGE_POST_CONTACTUS_FORM, formData);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || "Submission failed!");
      }
    }
  );


const contactUsPageSlice = createSlice({
    name:"contactuspage",
    initialState:{
        banner: {},
        site_general_info: {},
        fetchBannerStatus: 'idle', // Status for fetching the banner
        formSubmitStatus: 'idle',  // Status for submitting the form
        forminfoStatus: 'idle',  // Status for submitting the form
        bannerError: null,         // Error for fetching the banner
        formError: null,           // Error for submitting the form
        infoError: null,           // Error for submitting the form
        formResponse: null,        // Response for the submitted form
    },
    reducers:{},
    extraReducers:(builder)=>{  
        builder
        .addCase(fetchContactUsPageBanner.pending,(state)=>{
            state.fetchBannerStatus="loading"
        })
        .addCase(fetchContactUsPageBanner.fulfilled,(state,action)=>{
            state.fetchBannerStatus="succeeded";
            state.banner = action.payload?.banner;
        })
        .addCase(fetchContactUsPageBanner.rejected,(state,action)=>{
            state.fetchBannerStatus="failed";
            state.bannerError=action.error.message
        })

        // fetch site setting
        .addCase(fetchSiteGeneral_Information.pending,(state)=>{
            state.forminfoStatus="loading"
        })
        .addCase(fetchSiteGeneral_Information.fulfilled,(state,action)=>{
            state.forminfoStatus="succeeded";
            state.site_general_info = action.payload?.data?.data;
        })
        .addCase(fetchSiteGeneral_Information.rejected,(state,action)=>{
            state.forminfoStatus="failed";
            state.infoError=action.error.message
        })

         // Submit contact form
      .addCase(submitContactUsForm.pending, (state) => {
        state.formSubmitStatus = "loading";
      })
      .addCase(submitContactUsForm.fulfilled, (state, action) => {
        state.formSubmitStatus = "succeeded";
        state.formResponse = action.payload;
      })
      .addCase(submitContactUsForm.rejected, (state, action) => {
        state.formSubmitStatus = "failed";
        state.formError = action.payload || action.error.message;
      });
    }
})


export  default contactUsPageSlice.reducer;