import React from 'react';
import MonkEGold from "../../assets/monk-e-gold.png";
import { APISURL } from '../../contextApi/apiUrl';

const CardGrid = ({OurMilestoneCardsData}) => {
  const images = [
    { id: 1, imageSrc: MonkEGold },
    { id: 2, imageSrc: MonkEGold },
    { id: 3, imageSrc: MonkEGold},
    { id: 4, imageSrc: MonkEGold },
    { id: 5, imageSrc: MonkEGold },
    { id: 6, imageSrc: MonkEGold },
  ];

  return (
    <div className="flex justify-center items-center  p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {/* {images.map((image) => ( */}
        {OurMilestoneCardsData.map((image) => (
          <div
            key={image.id}
            className="bg-white rounded-xl shadow-lg overflow-hidden border border-gray-200"
            style={{ width: '302px',  }}
          >
            <div className="p-4 flex justify-center items-center rounded-xl">
              <img 
              // src={image.imageSrc} 
              src={`${APISURL.BASE_URL}${image.imageUrl}`} 
              alt={`Card ${image.id}`} 
              className="object-cover w-full h-full rounded-xl"
              style={{ height: '300px', width: '300px' }}
              onError={(e)=>{
                e.target.src=MonkEGold;
                e.target.onerror = null; // Prevent infinite loop in case fallback image also fails
              }}
               />
            </div>
          </div>  
        ))}
      </div>
    </div>
  );
};

export default CardGrid;
