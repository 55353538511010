import React from 'react';
import notfound from "../../assets/404.png";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <img src={notfound} alt="404 Not Found" className="w-full h-full max-w-[700px] mb-4" />
    </div>
  );
};

export default NotFoundPage;
