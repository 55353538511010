import React, { useEffect, useState } from 'react';
import InfluencerMarketingLogo from "../../assets/influencer-marketing.png";
import watchvideoicon from "../../assets/watch-video.png"
import { useSelector } from 'react-redux';
import { APISURL } from '../../contextApi/apiUrl';

const InfluencerMarketingSection = () => {
  const [showData,setShowData] = useState({})
  const InfluencerMarketingData = useSelector((state) => state.homepage.InfluencerMarketing);

  useEffect(()=>{
    console.log("InfluencerMarketingData", InfluencerMarketingData);
    if(InfluencerMarketingData){
      setShowData(InfluencerMarketingData)
    }
  },[InfluencerMarketingData])


   // Function to handle the Watch Video button click
   const handleWatchVideoClick = () => {
    const videoLink = showData?.button?.link;
    if (videoLink) {
      window.open(videoLink, "_blank"); // Open the link in a new tab
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-center px-4 md:px-8 ml-0 md:ml-[201px] font-['Urbanist'] w-full md:w-[1050px]">
      {/* Image Section */}
      <div className="w-full md:w-1/2 mb-6 md:mb-0">
        <img
          // src={InfluencerMarketingLogo}
          src={`${APISURL.BASE_URL}${showData?.imageUrl}`}
          alt="Influencer Marketing"
          className="rounded-lg shadow-lg w-full h-full object-cover"
          style={{height:"300px"}}
          onError={(e)=>{
            e.target.src=InfluencerMarketingLogo;
            e.target.onerror=null
          }}
        />
        <div className="absolute inset-0 from-transparent to-pink-100 rounded-lg"></div>
      </div>
      {/* Text Section */}
      <div className="w-full md:w-1/2 md:pl-14">
        <h2 className="text-3xl md:text-4xl font-semibold text-gray-900">
          {/* Who Are We?  */}
          {showData?.heading}
          <span className="text-gray-600 italic font-normal">JHS Media</span>
        </h2>
        <h3 className="text-3xl md:text-4xl font-semibold text-gray-900 mt-2">
          {/* Influencer Services */}
        </h3>
        <p className="text-sm md:text-base text-gray-700 mt-4 font-normal">
          {/* A bunch of changemakers in their early twenties who decided to change the way brands happen. Four years later, we are the innovative brains behind the digital marketing solutions of leading brands like Nykaa Man, Amazon Prime Video, Mama Earth, Livonser, Seeker Academy and many others. */}
          {showData?.description}
        </p>
        {/* <button className="flex items-center mt-6 hover:text-orange-600 font-semibold text-[20px] md:text-[24px] text-[#E54F26] leading-[24px] md:leading-[28.8px]">
          <img 
            src={watchvideoicon} 
            className="h-10 md:h-14 w-10 md:w-14 mr-3"
          />
          Watch Video
        </button> */}
      <button className="flex items-center mt-6 hover:text-orange-600 font-semibold text-[20px] md:text-[24px] text-[#E54F26] leading-[24px] md:leading-[28.8px]"
         onClick={handleWatchVideoClick} // Call the function on button click
      >
  <div className="relative">
    <span className="absolute inset-0 flex items-center justify-center">
      <span className="absolute inline-block h-8 w-8 md:h-10 md:w-10 rounded-full bg-[#E54F26] opacity-20 animate-ping"></span>
      <span className="absolute inline-block h-8 w-8 md:h-10 md:w-10 rounded-full bg-[#E54F26] opacity-30 animate-ping delay-100"></span>
      <span className="absolute inline-block h-8 w-8 md:h-10 md:w-10 rounded-full bg-[#E54F26] opacity-50 animate-ping delay-200"></span>
    </span>
    <img 
      src={watchvideoicon} 
      className="h-10 md:h-14 w-10 md:w-14 relative z-10 rounded-[60px]"
    />
  </div>
  {/* Watch Video */}
  {showData?.button?.name || "Watch Video"}
</button>



      </div>
    </div>
  );
};

export default InfluencerMarketingSection;
